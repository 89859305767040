import {Idable} from '@/models/Idable';
import {IImportTemplate} from '@/models/IImportTemplate';

export interface IImportRun extends Idable {
    description: string;
    importTemplate: IImportTemplate;
    appendOnly: boolean;
    haltOnError: boolean;
    filename: string;
    status: ImportJobStatus;
    startedOn: string;
    stoppedOn: string;

    endRow: number|null;
    startRow: number|null;

    totalCount: number;
    processedCount: number;
    skippedCount: number;
    skippedInfo: Map<number, string>;
    failedCount: number;
    failedInfo: Map<number, string>;
    addCount: number;
    addInfo: Map<number, string>;
    updateCount: number;
    updateInfo: Map<number, string>;
    errorMessage: string;
}

export enum ImportJobStatus {
    CREATED = 1,
    BUSY = 2,
    HALTED = 3,
    ERROR = 4,
    FINISHED_SUCCESS = 5,
    FINISHED_ERROR = 6,
    CANCELED = 7,
    PENDING = 8,
    CANCELED_SEQUENCE = 9,
    BAG_UPDATE = 10,
}
